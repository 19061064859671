import { Link } from "react-router-dom";
import "./Tutorials.css";
import { useContext, useState } from "react";
import { TutorialsContext } from "./TutorialsContext";

function Tutorials() {
  const tutorialsCollection = useContext(TutorialsContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className="Tutorials" id="tutorials-section">
      <div className="tutorials-page-headline">
        Find The Solution to Your Windows™ Problem
      </div>

      <div className="tutorials-page-text">
        Quickly troubleshoot and fix the most common Windows issues on your own
        with easy to follow help articles
      </div>

      {tutorialsCollection
        .slice(0, currentPage * itemsPerPage)
        .map((tutorial) => (
          <Link to={`/tutorials/${tutorial.id}`} className="tutorial-link">
            <div key={tutorial.id} className="tutorials-content-positioner">
              {/* <img
                src={tutorial.image[0]}
                alt={tutorial.title}
                className="tutorials-thumbnail"
              /> */}
              <div className="tutorials-text-positioner">
                <div className="tutorials-headline">{tutorial.title}</div>
                <div className="tutorials-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        tutorial.text
                          .slice(0, tutorial.text.lastIndexOf(" ", 200))
                          .replace(/,$/, "") + "...",
                    }}
                  />
                </div>
              </div>
            </div>
          </Link>
        ))}

      {tutorialsCollection.length > currentPage * itemsPerPage && (
        <div className="load-more-button-positioner">
          <button onClick={handleLoadMore} className="load-more-button-style">
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default Tutorials;
