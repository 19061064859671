import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="footer-container">
        <Link to="/contact" className="footer-item">
          Contact Us
        </Link>
        <Link to="/terms" className="footer-item">
          Terms
        </Link>
        <Link to="/privacy" className="footer-item">
          Privacy Policy
        </Link>

        {/* <Link to="/end-user-license-agreement" className="footer-item">
          End User License Agreement
        </Link>
        <Link to="/asr-uninstall-instructions" className="footer-item">
          ASR Uninstall Instructions
        </Link> */}
      </div>
    </div>
  );
};

export default Footer;
