 

import "./Contact.css";

const Contact = () => {
  return (
    <div className="Contact">
      <div className="contact-container">
        <div className="left-section">
          <div className="left-section-headline">
            My Windows Fix Office and Contact Info. <br></br>
            <br></br>Our Address is 2300 Lakeview Pkwy #700a, Alpharetta, GA
            30009. <br></br>
            <br></br>For support related questions, please reach out at
            support@mywindowsfix.com.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
