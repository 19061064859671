import "./LpHelpHero.css";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

function LpHelpHeroTy() {
  const tagManagerArgs = {
    gtmId: "GTM-P43S8B9",
  };

  TagManager.initialize(tagManagerArgs);
  useEffect(() => {
    window.dataLayer.push({
      event: "LpTyPageView",
      title: "LpHelpHeroTy.js",
    });
  }, []);

  const navigate = useNavigate();

  const handleTutorialClick = (e) => {
    e.preventDefault();
    navigate("/requestreceived");
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById("tutorials-section").offsetTop,
        behavior: "smooth",
      });
    }, 0);
  };

  return (
    <div className="LpHelpHeroTy">
      <div className="lp-help-hero-content-positioner-ty">
        <div className="lp-help-hero-headline-ty">
          Thank you, your request has been received, you will be contacted
          shortly
        </div>

        <div className="lp-help-hero-positioner-ty">
          <div className="lp-help-hero-button-ty" onClick={handleTutorialClick}>
            View Help Articles
          </div>
        </div>
      </div>
    </div>
  );
}

export default LpHelpHeroTy;
