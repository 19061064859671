import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import "./LpService.css";

library.add(faCheck);

function LpService() {
  return (
    <div className="LpService">
      <div className="pricing-container">
        <div className="pricing-container-headline">Live Service Plans </div>
        <div className="pricing-container-sub-headline">Monthly Pricing</div>
        <div className="pricing-boxes-positioner">
          <div className="pricing-box">
            <div className="pricing-box-headline">Basic Plan</div>
            <div className="pricing-box-subheadline">$19.99</div>
            <ul>
              <li className="pricing-text-point">PHONE SUPPORT</li>
              <li className="pricing-text-point">ONLINE HELP DESK</li>
              <li className="pricing-text-point">360 ANTI-VIRUS</li>
              <li className="pricing-text-point">ONLINE BACKUP 100GB</li>
              <li className="pricing-text-point">
                REMOTE SUPPORT: $20 PER INCIDENT
              </li>
              <li className="pricing-text-point">
                OONGOING MAINTENANCE: SEMI-ANNUALLY
              </li>
              <li className="pricing-text-point">
                ADDITIONAL DEVICE COVERAGE: $19.99/MO
              </li>
            </ul>
          </div>
          <div className="pricing-box pricing-box-middle-positioner">
            <div className="pricing-box-headline">Advanced Plan</div>
            <div className="pricing-box-subheadline">$29.99</div>
            <ul>
              <li className="pricing-text-point">PHONE SUPPORT</li>
              <li className="pricing-text-point">ONLINE HELP DESK</li>
              <li className="pricing-text-point">360 ANTI-VIRUS</li>
              <li className="pricing-text-point">ONLINE BACKUP 500GB</li>
              <li className="pricing-text-point">
                REMOTE SUPPORT: ONCE PER WEEK
              </li>
              <li className="pricing-text-point">
                OONGOING MAINTENANCE: QUARTERLY
              </li>
              <li className="pricing-text-point">
                ADDITIONAL DEVICE COVERAGE: $24.99/MO
              </li>
            </ul>
          </div>
          <div className="pricing-box">
            <div className="pricing-box-headline">Premium Plan</div>
            <div className="pricing-box-subheadline">$39.99</div>
            <ul>
              <li className="pricing-text-point">PHONE SUPPORT</li>
              <li className="pricing-text-point">ONLINE HELP DESK</li>
              <li className="pricing-text-point">360 ANTI-VIRUS</li>
              <li className="pricing-text-point">ONLINE BACKUP 1TB</li>
              <li className="pricing-text-point">REMOTE SUPPORT: UNLIMITED</li>
              <li className="pricing-text-point">
                OONGOING MAINTENANCE: MONTHLY
              </li>
              <li className="pricing-text-point">
                ADDITIONAL DEVICE COVERAGE: $29.99/MO
              </li>
            </ul>
          </div>
        </div>
        <div className="pricing-box-sub-paragraph">
          How Monthly Service Plans Work: Sign up for a monthly plan and your
          credit/debit card will be charged $19.99-$39.99, depending on the
          chosen plan. The same charge will be made on the same day each month
          until you cancel the plan. After payment and registration, we'll
          perform a full diagnostic, scan, cleaning, and optimization of your
          device with your permission. We'll set up your data backup and
          anti-virus based on your plan, and perform a scheduled tune-up and
          optimization semi-annually, quarterly, or monthly. You'll get two
          no-cost remote support sessions with the premium plan, one with the
          advanced, and extra sessions will incur an additional $20 charge.
          Contact us through phone, email, or contact form for additional
          support. Third-party service providers have their own pricing and
          service structure, please review before entering any agreement.
          Contact support@mywindowsfix.com for any questions about the monthly
          plans or to cancel at any time.
        </div>
      </div>
    </div>
  );
}

export default LpService;
