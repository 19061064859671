import "./LpHelp.css";
import LpHelpHero from "./LpHelpHero";
import Tutorials from "./Tutorials";
import LpHelpHeroForm from "./LpHelpHeroForm";
import LpService from "./LpServices";
import HeaderLp from "./HeaderLp";
import LpHelpHeroInfo from "./LpHelpHeroInfo";

import TagManager from "react-gtm-module";

import { useEffect, useState } from "react";

function LpHelp() {
  const tagManagerArgs = {
    gtmId: "GTM-P43S8B9",
  };

  TagManager.initialize(tagManagerArgs);
  useEffect(() => {
    window.dataLayer.push({
      event: "LpHelpPageView",
      title: "LpHelp.js",
    });
  }, []);

  // lead form on
  const [componentsOrder, setComponentsOrder] = useState([
    <LpHelpHeroForm />,
    <LpHelpHeroInfo />,
    <LpHelpHero />,
    <Tutorials />,
    <LpService />,
  ]);

//lead form off
  // const [componentsOrder, setComponentsOrder] = useState([
  //   <LpHelpHero />,
  //   <Tutorials />,
  //   <LpHelpHeroInfo />,
  //   <LpHelpHeroForm />,
  //   <LpService />,
  // ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const keyword = urlParams.get("kw");
    if (keyword) {
      const sanitizedKeyword = keyword
        .replace(/[^a-z0-9\s]/gi, "")
        .toLowerCase();
      const keywordWords = sanitizedKeyword.split(" ");

      // Logic for setting component order based on multiple keywords
      if (keywordWords.includes("tutorial")) {
        setComponentsOrder([
          <Tutorials />,
          <LpHelpHero />,

          <LpHelpHeroInfo />,

          // <LpHelpHeroForm />,
          // <LpService />,
        ]);
      } else if (
        keywordWords.some((word) =>
          [
            "g",
            "mail",
            "email",
            "account",
            "login",
            "check",
            "sign",
            "log",
            "signin",
            "password",
            "recover",
            "address",
            "open",
            "inbox",
            "yahoo",
            "online",
            "emails",
            "access",
            "att",
            "outlook",
            "gmail",
            "gmaill",
            "accounts",
            "hotmail",
            "e",
            "mails",
            "aol",
            "www.comcast.net",
            "google",
            "issues",
            "net",
            "centurylink",
            "microsoft",
            "help",
            "computer",
            "reset",
            "pc",
            "ymail",
            "windows",
            "fix",
            "att",
            "emails",
          ].includes(word)
        )
      ) {
        setComponentsOrder([
          // leads on
          <LpHelpHeroForm />,
          <LpHelpHeroInfo />,
          <LpHelpHero />,
          <Tutorials />,
          <LpService />,

          //leads off
          // <LpHelpHero />,
          // <Tutorials />,
          // <LpHelpHeroInfo />,
          // <LpHelpHeroForm />,
          // <LpService />,
        ]);
      }
    } else {
      setComponentsOrder([
        <LpHelpHeroForm />,
        <LpHelpHeroInfo />,
        <LpHelpHero />,
        <Tutorials />,
        <LpService />,




        // <LpHelpHero />,
        // <Tutorials />,
        // <LpHelpHeroInfo />,
        // <LpHelpHeroForm />,
        // <LpService />,
      ]);
    }
  }, []);

  return (
    <div className="LpHelp">
      <HeaderLp />
      {componentsOrder}
    </div>
  );
}

export default LpHelp;

// based on url keyword, dynamically display relevant component order.

// useEffect(() => {
//   const urlParams = new URLSearchParams(window.location.search);
//   const keyword = urlParams.get("kw");
//   if (keyword) {
//     const sanitizedKeyword = keyword
//       .replace(/[^a-z0-9\s]/gi, "")
//       .toLowerCase();
//     const keywordWords = sanitizedKeyword.split(" ");
//     let componentIsSet = false;
//     // Object.entries(possibleHeadlines).forEach(([key, value]) => {
//     //   if (!headlineSet) {,
//     //     keywordWords.forEach((word) => {
//     //       if (key.toLowerCase() === word.toLowerCase()) {
//     //         setHeadline(value);
//     //         componentIsSet = true;
//     //       }
//     //     });
//     //   }
//     // });
//   }
// }, []);

/// this condition would check for g mail with space...
// else if (
//   keywordWords.includes("g") && keywordWords.includes("mail")
// ) {
//   setComponentsOrder([
//       <LpHelpHeroForm />,
//       <LpHelpHeroInfo />,
//       <LpHelpHero />,
//       <Tutorials />,
//       <LpService />,
//   ]);
// }
