//import logo from "./logo.svg";
import "./Header.css";
import HeaderNav from "./HeaderNav";
import { Link } from "react-router-dom";

// import { useLocation } from "react-router-dom";

function Header() {
  // const location = useLocation();

  return (
    <div className="Header">
      <div className="header-left-slant"></div>
      <header className="header-middle">
        <Link to="/">
          <div className="logo-text-and-icon-positioner">
            <div className="logo-text-positioner">MY WINDOWS FIX</div>
            <img
              className="logo-icon-positioner"
              src="/windowsfix.png"
              alt="My Windows Fix"
              height="50px"
            ></img>
          </div>
        </Link>
        {/* {location.pathname !== "/computertutorials" && <HeaderNav />} */}
        <HeaderNav />
      </header>
      <div className="header-right-slant"></div>
    </div>
  );
}

export default Header;
