import "./App.css";
import Hero from "../src/components/Hero";
import Tutorials from "./components/Tutorials";

function App() {
  return (
    <div className="App">
      <Hero />
      <Tutorials />
    </div>
  );
}

export default App;
