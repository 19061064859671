import "./Privacy.css";
import React from "react";
import Contact from "./Contact";

const Privacy = () => {
  return (
    <div>
      <div className="Privacy">
        <h1>MyWindowsFix Privacy Policy</h1>
        <p>
          At MyWindowsFix, we are committed to protecting the privacy of our
          customers and users. This privacy policy outlines the types of
          personal information we collect, how we use it, and the steps we take
          to protect it.
        </p>
        <h2>Information We Collect</h2>
        <p>
          When you purchase or download a product from MyWindowsFix, we may
          collect certain personal information such as your name, email address,
          and payment information. We may also collect information about your
          device and its software, as well as usage data such as how often you
          use our products and any error reports you may generate.
        </p>
        <h2>Use of Personal Information</h2>
        <p>
          The personal information we collect is used to process your purchase
          and provide customer support. We may also use it to improve our
          products and services, or to send you information about our company or
          special offers. If your issue cannot be resolved through our library
          of help articles and you request live help, we may match your request
          with a third party support provider. My Windows Fix may sell,
          transfer, or disclose your contact information as submitted in your
          support request submission in order to allow the third party support
          provider to contact you. Information shared with said third party
          support provider may include your name, email, or phone number, as
          submitted at the time of your support request. My Windows Fix will not
          share any other personal information with any third party support
          provider. My Windows Fix may sell, transfer, or disclose your contact
          information as provided in your help request submission to its
          affiliates, licensees, partners, or other third parties in order to
          facilitate your live support request. My Windows Fix takes the safety
          of our users seriously, please contact us at support@mywindowsfix.com
          if you have any questions or concerns with any third party support
          provider. My Windows Fix will never share payment details or payment
          info with any third party or outside company<br></br>We may also use
          your personal information to send you marketing communications, such
          as newsletters, special offers, and promotions. You can opt-out of
          receiving these communications at any time by clicking the unsubscribe
          link in the email or by contacting us at support@mywindowsfix.com
        </p>
        <h2>Protection of Personal Information</h2>
        <p>
          MyWindowsFix takes all reasonable precautions to protect your personal
          information from unauthorized access, use, or disclosure. We use
          secure servers and encryption technologies to safeguard your personal
          information, and only authorized personnel have access to your
          information.
        </p>
        <h2>Retargeting, Cookies, and Remarketing</h2>
        <p>
          MyWindowsFix may use cookies or other similar technologies to collect
          information about your browsing activities and interests, both on our
          website and on other websites across the Internet. This information
          may be used to deliver targeted advertising to you while you are
          browsing other websites, based on your interests and browsing history.
        </p>
        <p>
          We may also use retargeting technologies to show you ads for our
          products on other websites after you have visited our website. These
          ads may be based on your browsing history on our website and the
          products you have viewed or purchased.
        </p>
        <p>
          You can opt-out of targeted advertising by visiting the Network
          Advertising Initiative Opt-Out Page or the Digital Advertising
          Alliance Opt-Out Page.
        </p>
        <h2>Ad Targeting</h2>
        <p>
          We may use third-party ad networks to deliver targeted ads to you.
          These networks may use cookies or other similar technologies to
          collect information about your browsing activities and interests, both
          on our website and on other websites across the Internet. This
          information may be used to deliver targeted advertising to you while
          you are browsing other websites.
        </p>
        <p>
          You can opt-out of targeted advertising by visiting the Network
          Advertising Initiative Opt-Out Page or the Digital Advertising
          Alliance Opt-Out Page.
        </p>
        <h2>Changes to Privacy Policy</h2>
        <p>
          MyWindowsFix reserves the right to make changes to this privacy policy
          at any time. If changes are made, we will post the updated policy on
          our website. Your continued use of our products and services after
          changes have been made to the policy constitutes your acceptance of
          the changes. If you have any questions about our privacy policy,
          please contact us at support@mywindowsfix.com.
        </p>
      </div>
      <Contact />;
    </div>
  );
};

export default Privacy;
