import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import App from "./App";
import Tutorial from "./components/Tutorial";
import { TutorialsContext } from "./components/TutorialsContext";
import tutorialsCollection from "./json-data/tutorials.json";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Lp1 from "./components/Lp1";
import { useLocation } from "react-router-dom";
import LpHelp from "./components/LpHelp";
import LpHelpTy from "./components/LpHelpTy";

const RouteWithHeader = () => {
  const { pathname } = useLocation();
  return (
    <>
      {!pathname.startsWith("/computertutorials/") && <Header />}
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="tutorials/:id" element={<Tutorial />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/lp1" element={<Lp1 />} />
        <Route path="/lphelp" element={<LpHelp />} />
        <Route path="/computertutorials" element={<LpHelp />} />
        <Route path="/requestreceived" element={<LpHelpTy />} />
      </Routes>
      <Footer />
    </>
  );
};

const RouteSwitch = () => {
  return (
    <TutorialsContext.Provider value={tutorialsCollection}>
      <BrowserRouter>
        <RouteWithHeader />
      </BrowserRouter>
    </TutorialsContext.Provider>
  );
};

export default RouteSwitch;
