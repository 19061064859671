import "./Hero.css";
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();

  const handleTutorialClick = (e) => {
    e.preventDefault();
    navigate("/");
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById("tutorials-section").offsetTop,
        behavior: "smooth",
      });
    }, 0);
  };

  return (
    <div className="Hero">
      <div className="hero-content-positioner">
        <div className="hero-headline">
          Simple Solutions For Common Windows™ Problems
        </div>
        <div className="hero-text">
          Quickly troubleshoot and fix the most common Windows issues on your
          own with easy to follow help articles
        </div>
        <div className="hero-button" onClick={handleTutorialClick}>
          View Help Articles
        </div>
      </div>
    </div>
  );
}

export default Hero;
