import "./LpHelpHero.css";

function LpHelpHeroInfo() {
  return (
    <div className="lp-help-hero-info-paragraph">
      <div className="lp-help-hero-info-positioner">
        <div className="lp-help-hero-info-paragraph-headline">
          Why Choose MyWindowsFix?
        </div>
        <div className="lp-help-hero-info-paragraph-text">
          MyWindowsFix is on a mission to become a leading provider of online
          technical help articles and tutorials for desktops, laptops, mobile
          devices, and tablets. We also provide instant live help for all your
          technical issues in the event that you cannot resolve your issue by
          using our tutorials.
          <br></br>
          <br></br>
          Many Windows users experience issues with home page redirects, adware,
          or malware, which can make it hard to find the pages they are looking
          for. We want to help solve the issues as quickly as possible with our
          library of simple to follow tutorials, but sometimes users need live
          help, which is why we provide remote support matching services. Our
          third-party support partners offer a wide range of options to help you
          diagnose and repair existing issues to help you get back to browsing
          the web, checking your email, or browsing social media. Our help
          center resources are available 24/7 to provide you with instant,
          reliable, and easy troubleshooting tools.
          <br></br>
          <br></br>
          We are committed to providing you with the best possible experience
          and will do everything we can to ensure your satisfaction. Please note
          that we do not charge our users for diagnostic support matching, and
          all repairs or services will be clearly quoted by our third-party
          support partners before any work is performed. The service pricing
          quoted on this page represents the cost of service if working with
          MyWindowsFix directly. Please note that third-party support partners
          may have different pricing structures.
        </div>
      </div>
    </div>
  );
}

export default LpHelpHeroInfo;
