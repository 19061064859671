import "./LpHelp.css";

import Tutorials from "./Tutorials";

import LpHelpHeroTy from "./LpHelpHeroTy";
// import TagManager from "react-gtm-module";
// import { useEffect } from "react";

// import ReactGA from "react-ga";

function LpHelpTy() {
  return (
    <div className="LpHelpTy">
      <LpHelpHeroTy />
      <Tutorials />
    </div>
  );
}

export default LpHelpTy;
