//import logo from "./logo.svg";
import "./Header.css";
// import HeaderNav from "./HeaderNav";
import { Link } from "react-router-dom";

// import { useLocation } from "react-router-dom";

function HeaderLp() {
  // const location = useLocation();

  return (
    <div className="HeaderLp">
      <div className="header-left-slant-lp"></div>
      <header className="header-middle-lp">
        <Link to="/">
          <div className="logo-text-and-icon-positioner-lp">
            <div className="logo-text-positioner-lp">MY WINDOWS FIX</div>
            <img
              className="logo-icon-positioner-lp"
              src="/windowsfixlp.png"
              alt="My Windows Fix"
              height="50px"
            ></img>
          </div>
        </Link>
        {/* {location.pathname !== "/computertutorials" && <HeaderNav />} */}
      </header>
      <div className="header-right-slant-lp"></div>
    </div>
  );
}

export default HeaderLp;
