import "./Lp1.css";
import { BiDownload } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoShieldCheckmarkOutline } from "react-icons/io5";

function Lp1() {
  const [platform, setPlatform] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const keyword = searchParams.get("keyword");

  useEffect(() => {
    if (navigator.platform === "Win32") {
      setPlatform("Windows 11");
    } else if (navigator.platform === "Win64") {
      setPlatform("Windows 10");
    } else if (navigator.platform === "Windows") {
      setPlatform("Windows 8 or earlier");
    } else {
      setPlatform(navigator.platform);
    }
  }, []);

  let dynamic = "Windows Errors";
  if (keyword) {
    let capitalizedKeyword = keyword
      .split("_")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
    dynamic = ` ${capitalizedKeyword}`;
  }

  console.log(keyword);

  return (
    <div className="LpTopContainer">
      <div className="lp-header">
        <header className="lp-header-middle">
          <Link to="/">
            <div className="lp-logo-text-and-icon-positioner">
              <div className="lp-logo-text-positioner">MY WINDOWS FIX</div>
              <img
                className="lp-logo-icon-positioner"
                src="/windowsfix.png"
                alt="My Windows Fix"
                height="50px"
              ></img>
            </div>
          </Link>
        </header>
      </div>
      <div className="lp-positioner">
        <div className="lp-top-container-headline">How to Fix {dynamic}</div>

        <div className="lp-top-container-subheadline-banner">
          Support Home > Windows > How to Fix {dynamic}
        </div>
        <div className="left-and-right-container">
          <div className="LpLeftColumn">
            <div className="lp-left-column-headline">
              PC Automatic Repair Tool (Advanced System Repair)
            </div>
            <div className="lp-system-icon-summary-container">
              <div className="lp-system-icon">
                {<IoShieldCheckmarkOutline />}
              </div>
              <div className="lp-system-summary-text-container">
                <div className="lp-system-summary-text-headline">
                  System Information
                </div>
                <div className="lp-system-summary-text-subheadline">
                  You are currently running {platform}. <br />
                  Advanced System Repair is compatible with your operating
                  system
                </div>
              </div>
            </div>
            {/* END OF SYSTEM ICON SUMMARY CONTAINER */}
            <div className="lp-lanuage-and-download-container">
              <div className="lp-language-and-download-container-top">
                {" "}
                <div className="lp-language-and-download-detected-language">
                  Detected System Language: <strong>English</strong>
                </div>
                <a
                  href="https://link.advancedsystemrepairpro.com/913c4547"
                  className="download-button-positioner"
                >
                  <div className="lp-language-and-download-button">
                    Download Now
                  </div>
                </a>
              </div>
              <div className="lp-language-and-download-container-bottom">
                {" "}
                <div className="lp-language-and-download-links">
                  <a
                    href="https://advancedsystemrepair.com/EULA.php"
                    target="blank"
                  >
                    EULA
                  </a>
                  &nbsp; |&nbsp;
                  <a
                    href="https://advancedsystemrepair.com/Uninstall.php"
                    target="blank"
                  >
                    Uninstall Instructions
                  </a>
                </div>
              </div>
            </div>{" "}
            {/* END OF LP LANGUAGE AND DOWNLOAD CONTAINER */}
            <div className="left-info-section">
              <div className="left-info-section-headline">
                What is {dynamic}?
              </div>
              <div className="left-info-section-paragraph">
                Windows 10 Error is a common error that can have a number of
                causes, including but not limited to: incomplete windows update,
                firewall settings, installing or unistalling various programs,
                hardware issues, malware, spyware, or adware. This list is not
                exhaustive as there are many potential causes, so a reputable
                scan and repair tool is recommended to fix this error.
              </div>
              <div className="left-info-section-headline">
                Recommended repair steps
              </div>
              <div className="left-info-section-paragraph">
                My Windows Fix recommends that you try the following steps to
                fix this error: Download the Advanced System Repair tool to
                quickly find and repair Windows errors. This tool will repair
                common computer errors, protect you from file loss, malware,
                hardware failure and optimize your PC for maximum performance.
                Fix Windows erros and optimize your PC.
              </div>
              <div className="left-info-section-headline">
                Automatically Fix a Range of Errors With This Tool
              </div>
              <div className="left-info-section-paragraph">
                <strong>Step 1:</strong>{" "}
                <u>
                  <a href="https://link.advancedsystemrepairpro.com/913c4547">
                    Download Advaned System Repair here.{" "}
                  </a>
                </u>
                After the program has downloaded follow the prompts to install.
                <br /> <br />
                <strong>Step 2:</strong> Click the "Scan" button to detect
                errors and problems that may be causing your computer to
                malfunction.
                <br /> <br />
                <strong>Step 3:</strong> Click the "Fix All" button to apply
                Advanced System Repair recommended fixes.
              </div>
              <div className="left-info-links">
                {" "}
                <a
                  href="https://advancedsystemrepair.com/EULA.php"
                  target="blank"
                >
                  EULA
                </a>
                &nbsp; | &nbsp;
                <a
                  href="https://advancedsystemrepair.com/Uninstall.php"
                  target="blank"
                >
                  Uninstall Instructions
                </a>
              </div>
              <div className="left-info-section-headline">
                What will This Tool Do?
              </div>
              <div className="left-info-section-paragraph">
                This tool will repair common computer errors, protect you from
                file loss, malware, hardware failure and optimize your PC for
                maximum performance. Fix Windows errors and optimize your PC
                performance in a few clicks.
              </div>
            </div>
          </div>

          <div className="LpRightColumn">
            <div className="right-column-banner-container">
              <div className="right-column-banner-headline">
                Advanced System Repair Authorized Distributor
              </div>
              <div className="right-column-banner-download-link-container">
                <div className="right-column-download-icon">
                  {<BiDownload />}
                </div>
                <div className="right-column-download-link">
                  <a href="https://link.advancedsystemrepairpro.com/913c4547">
                    Download Now
                  </a>
                </div>
              </div>
              <div className="right-column-banner-sub-headline">
                STEP BY STEP INSTRUCTIONS
              </div>
              <div className="right-column-banner-line-item-container">
                <div className="right-column-banner-line-left">Step 1:</div>
                <div className="right-column-banner-line-right">
                  Download tool
                </div>
              </div>
              <div className="right-column-banner-line-item-container">
                <div className="right-column-banner-line-left">Step 2:</div>
                <div className="right-column-banner-line-right">
                  Install tool
                </div>
              </div>
              <div className="right-column-banner-line-item-container">
                <div className="right-column-banner-line-left">Step 3:</div>
                <div className="right-column-banner-line-right">
                  Click "Scan Now" to scan your PC
                </div>
              </div>
              <div className="right-column-banner-line-item-container">
                <div className="right-column-banner-line-left">Step 4:</div>
                <div className="right-column-banner-line-right">
                  Click "Fix All" to fix errors
                </div>
              </div>
              <div className="right-column-banner-line-specs-container">
                <div className="right-column-banner-line-left">
                  Version Requrements:
                </div>
                <div className="right-column-banner-line-right-specs">
                  Windows 11: 32 and 64 bit, Windows 10: 32 and 64 bit, Windows
                  8.1: 32 and 64 bit, Windows 8 :32 and 64 bit, Windows 7: 32
                  and 64 bit, Windows Vista: 32 and 64 bit, Windows XP: 32 and
                  64 bit.
                </div>
              </div>
              <div className="right-column-banner-disclaimer-container">
                <strong>Disclaimer:</strong> MyWindowsFix.com is an authorized
                distributor of Advanced System Repair. Please contact Advanced
                System Repair directly for product support. Paid access to
                Advanced System Care starts at $19.95. The owner of
                MyWindowsFix.com is compensated for each download or sale of
                Advanced System Care.
              </div>
              {/* <div className="right-column-banner-line-specs-container">
              <div className="right-column-banner-line-left">Downloads:</div>
              <div className="right-column-banner-line-right">3234552+</div>
            </div>
            <div className="right-column-banner-line-specs-container">
              <div className="right-column-banner-line-left">
                Download size:
              </div>
              <div className="right-column-banner-line-right">322.5 MB</div>
            </div>
            <div className="right-column-banner-line-specs-container">
              <div className="right-column-banner-line-left">Requirements:</div>
              <div className="right-column-banner-line-right">
                1ghz 1gb ram 50mb hard drive space
              </div>
            </div> */}
            </div>
          </div>
        </div>

        <div className="lp-footer">
          <div className="lp-footer-links">
            <div className="lp-footer-link">
              <a
                href="https://advancedsystemrepair.com/EULA.php"
                target="blank"
              >
                EULA
              </a>
            </div>
            <div className="lp-footer-link">
              {" "}
              <a
                href="https://advancedsystemrepair.com/Uninstall.php"
                target="blank"
              >
                Uninstall Instructions
              </a>
            </div>
          </div>

          <div className="lp-footer-copyright">© 2023 All Rights Reserved</div>
          <div className="lp-footer-disclaimer">
            Disclaimer: This website is not affiliated with Microsoft
            Corporation,
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lp1;
