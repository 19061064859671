import { useParams } from "react-router-dom";
import { useContext } from "react";
import { TutorialsContext } from "./TutorialsContext";
import "./Tutorial.css";
import { useEffect, useRef } from "react";

function Tutorial() {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      window.scrollTo(0, 0);
      firstRender.current = false;
    }
  }, []);

  const { id } = useParams();
  const tutorialsCollection = useContext(TutorialsContext);
  const tutorial = tutorialsCollection.find((t) => t.id === id);

  return tutorial ? (
    <div className="Tutorial">
      <div className="tutorial-text-positioner">
        <div className="tutorial-headline">{tutorial.title}</div>

        <img
          src={tutorial.image[0]}
          alt={tutorial.title}
          className="tutorial-thumbnail"
        />
        <div className="tutorial-text">
          <div dangerouslySetInnerHTML={{ __html: tutorial.text }} />
        </div>
      </div>
    </div>
  ) : (
    <div>Tutorial not found</div>
  );
}

export default Tutorial;
