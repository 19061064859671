import React, { useState, useEffect } from "react";

function LpHelpHeroForm() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
  });
  // const [headline, setHeadline] = useState(
  //   "Still Having Issues? Get Live Help Now. Sale of Consumer Technology May Apply, See Pricing Below"
  // );
  const [headline, setHeadline] = useState(
    "Email Problems? Get Instant Help Now"
  );
  const [submitting, setSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedFailure, setFormSubmittedFailure] = useState(false);

  const [phoneError, setPhoneError] = useState(null);

  useEffect(() => {
    const possibleHeadlines = {
      // email:
        // "Still Having Issues? Get Live Help Now. Sale of Consumer Technology May Apply, See Pricing Below",
      email: "Email Problems? Get Instant Help Now",
      webmail: "Email Problems? Get Instant Help Now",
      mail: "Email Problems? Get Instant Help Now",
      gmail: "Email Problems? Get Instant Help Now",
      ymail: "Email Problems? Get Instant Help Now",
      outlook: "Email Problems? Get Instant Help Now",
      google: "Email Problems? Get Instant Help Now",
      computer: "Computer Problems? Get Instant Help Now",
      pc: "Computer Problems? Get Instant Help Now",
      error: "Error Messages? Get Instant Help Now",
      blue: "Blue Screen? Get Instant Help Now",
      slow: "Slow Computer Problems? Get Instant Help Now",
      dll: "DLL Errors? Get Instant Help Now",
    };

    const urlParams = new URLSearchParams(window.location.search);
    const keyword = urlParams.get("kw");
    if (keyword) {
      const sanitizedKeyword = keyword
        .replace(/[^a-z0-9\s]/gi, "")
        .toLowerCase();
      const keywordWords = sanitizedKeyword.split(" ");
      let headlineSet = false;

      Object.entries(possibleHeadlines).forEach(([key, value]) => {
        if (!headlineSet) {
          keywordWords.forEach((word) => {
            if (key.toLowerCase() === word.toLowerCase()) {
              setHeadline(value);
              headlineSet = true;
            }
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    // if submitted redirect to thank you page
    if (formSubmitted) {
      window.location = "/requestreceived";
    }
  }, [formSubmitted]);

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const postContact = (e) => {
    e.preventDefault();

    // Phone Validation
    if (form.phone && !form.phone.match(/^[\d\s\-()]{10,13}$/)) {
      setPhoneError("Invalid phone number");
      return; // Stop the function execution
    } else {
      setPhoneError(null); // Clear any previous error
    }

    setSubmitting(true);
    fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((res) => {
        if (res.ok) {
          setFormSubmitted(true);
        } else {
          setFormSubmittedFailure(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setFormSubmittedFailure(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="LpHelpHeroForm" id="live-help-section">
      <div className="lp-help-hero-form-content-positioner">
        <div className="lp-help-hero-form-top-section-positioner">
          <div className="lp-help-hero-form-headline">{headline}</div>
          <div className="lp-help-hero-form-text">
            Get diagnostic help now, enter your info in the form below to
            receive instant help.
          </div>
          {formSubmitted && (
            <div className="lp-help-hero-form-success">
              <div className="lp-help-hero-form-success-text">
                Thank you for contacting us, we will be in touch shortly.
              </div>
            </div>
          )}
          {formSubmittedFailure && (
            <div className="lp-help-hero-form-failure">
              <div className="lp-help-hero-form-failure-text">
                Sorry, there was an error submitting your request, please try
                again.
              </div>
            </div>
          )}
          {!formSubmitted && !formSubmittedFailure && (
            <>
              <form className="lp-help-hero-form" onSubmit={postContact}>
                <input
                  className="lp-help-form-input-style"
                  type="text"
                  name="name"
                  placeholder="Name"
                  // required
                  onChange={handleChange}
                  value={form.name}
                />
                <input
                  className="lp-help-form-input-style"
                  type="email"
                  name="email"
                  placeholder="Email"
                  // required
                  onChange={handleChange}
                  value={form.email}
                />
                {phoneError && (
                  <div className="lp-help-hero-form-error">{phoneError}</div>
                )}
                <input
                  className="lp-help-form-input-style"
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  required
                  onChange={handleChange}
                  value={form.phone}
                  pattern="^\+?[\d\s\-()]{10,15}$" // Regex pattern adjusted to include parentheses
                  title="Phone number must be between 10 and 13 characters and can include digits, spaces, hyphens, and parentheses."
                />

                <button
                  className="lp-help-hero-form-button"
                  type="submit"
                  disabled={submitting}
                >
                  Get Help Now{" "}
                </button>
              </form>
              <div className="lp-help-hero-form-privacy">
                By submitting the form you agree to our{" "}
                <a href="/privacy" target="blank">
                  privacy policy.
                </a>
              </div>
            </>
          )}

          <div className="lp-help-hero-form-disclaimer">
            "Disclaimer: Mywindowsfix provides online resources and live
            diagnostic and technical problem assistance for desktops, laptops,
            mobile devices, and tablets. We are an INDEPENDENT third-party
            provider and have NO AFFILIATION with any brand, trademark, logo, or
            company name mentioned. These brand names, trademarks, and logos are
            the property of their respective owners and are used for
            illustration purposes only."
          </div>
        </div>
      </div>
    </div>
  );
}

export default LpHelpHeroForm;
