// import { Link } from "react-router-dom";
import "./HeaderNav.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function HeaderNav() {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const handleTutorialClick = (e) => {
    e.preventDefault();
    navigate("/");
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById("tutorials-section").offsetTop,
        behavior: "smooth",
      });
    }, 0);
  };

  return (
    <div className="HeaderNav">
      <nav>
        <ul className="header-hav-positioner">
          <Link to="/" onClick={handleClick}>
            <li> Home</li>
          </Link>
          <Link to="/" onClick={handleTutorialClick}>
            <li> Tutorials</li>
          </Link>
          <Link to="/contact">
            <li>Contact</li>
          </Link>
        </ul>
      </nav>
    </div>
  );
}

export default HeaderNav;
