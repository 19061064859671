import "./LpHelpHero.css";
import { useNavigate } from "react-router-dom";

function LpHelpHero() {
  const navigate = useNavigate();

  const handleTutorialClick = (e) => {
    e.preventDefault();
    // navigate("../");
    navigate("/computertutorials/");
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById("tutorials-section").offsetTop,
        behavior: "smooth",
      });
    }, 0);
  };

  // const handleHelpClick = (e) => {
  //   e.preventDefault();
  //   navigate("/computertutorials");
  //   setTimeout(() => {
  //     window.scrollTo({
  //       top: document.getElementById("live-help-section").offsetTop,
  //       behavior: "smooth",
  //     });
  //   }, 0);
  // };

  return (
    <div className="LpHelpHero">
      <div className="lp-help-hero-content-positioner">
        <div className="lp-help-hero-headline">
          Simple Solutions For Common Computer Problems
        </div>
        <div className="lp-help-hero-text">
          Quickly troubleshoot and fix the most common computer issues on your
          own with easy to follow help articles
        </div>

        <div className="lp-help-hero-positioner">
          <div className="lp-help-hero-button" onClick={handleTutorialClick}>
            View Help Articles
          </div>
          {/* <div className="lp-help-button-text">or</div>
          <div className="lp-help-hero-button-second" onClick={handleHelpClick}>
            Get Live Help
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default LpHelpHero;
