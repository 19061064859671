import React from "react";
import "./Terms.css";
import Contact from "./Contact";

const Terms = () => {
  return (
    <div>
      <div className="terms-conditions">
        <h1>Terms of Use</h1>
        <p>
          Please review these terms and conditions carefully before using the
          services of myWindowsFix. By submitting payment or accepting services
          from myWindowsFix, you are confirming that you have read and agreed to
          all the terms and conditions in full. If you do not agree to these
          terms, you will not be eligible to receive services from myWindowsFix
          or its employees.
        </p>
        <p>
          myWindowsFix reserves the right to modify or amend these terms and
          conditions at any time. Please check these terms and conditions before
          each site usage to make sure you are aware of any potential changes.
        </p>
        <h4>Eligibility</h4>
        <p>
          To receive services from myWindowsFix, you must agree to the following
          conditions:
        </p>
        <ul>
          <li>You are over 18 years of age.</li>
          <li>
            You are the sole owner of the device that needs to be serviced.
          </li>
          <li>The device is not a work computer or shared office computer.</li>
          <li>
            The information provided to myWindowsFix is accurate to the best of
            your knowledge.
          </li>
          <li>
            You grant myWindowsFix and its employees the right to access your
            device for the purpose of providing agreed-upon services.
          </li>
        </ul>
        <h4>Right to Access and Modify Your Device</h4>
        <p>
          By accepting services from myWindowsFix, you grant the company access
          to your device to resolve any issues. You authorize myWindowsFix or
          your matched third party support provider to modify programs, files,
          and other items on your device as necessary to perform the required
          service. myWindowsFix may download and install applications and
          programs on your device to diagnose and correct issues and protect it
          in the future. Service on your device will be provided by myWindowsFix
          and its employees. Please note that myWindowsFix will not be held
          responsible for any data or software that may be lost or corrupted.
        </p>
        <h4>Payment for Services</h4>
        <p>
          You authorize myWindowsFix to charge your credit or debit card or bank
          account for the services provided. The cost may be a one-time fee,
          flat fee, or a monthly recurring fee, depending on the selected
          service. If you choose a monthly service package, a payment compliance
          specialist will explain the payment plan and terms of the service
          contract at the time of registration. You must verbally agree to the
          monthly service plan before being enrolled in it.
        </p>
        <p>
          Monthly support fees range from $19.99 to $39.99, based on the
          selected service package. myWindowsFix reserves the right to change
          its pricing at any time, and any changes will be communicated to you
          via email. Please note that matched third party support providers have
          their own pricing and payment terms which will be clearly explained
          before any service is performed.
        </p>
        <p>
          myWindowsFix reserves the right to terminate its services to you if
          payments are not received on time. If you dispute any charges on your
          account, you must notify myWindowsFix within 30 days of the charge, or
          the dispute will be considered waived.
        </p>
        <h4>Warranty and Limitation of Liability</h4>
        <p>
          myWindowsFix provides its services "as is" and makes no warranties,
          express or implied, as to the quality or accuracy of the services
          provided. myWindowsFix will not be held responsible for any damages,
          direct or indirect, arising from the use of its services, including
          loss of data or software, damage to your device, or any other indirect
          damages.
        </p>
        <p>
          myWindowsFix will use reasonable care to diagnose and correct issues
          with your device. However, myWindowsFix cannot guarantee that the
          services provided will correct all issues or that your device will be
          free from future issues.
        </p>
        <p>
          You agree to indemnify and hold harmless myWindowsFix and its
          employees from any claims, damages, or expenses arising from the use
          of its services.
        </p>
        <h4>Intellectual Property</h4>
        <p>
          The content, trademarks, and logos on the myWindowsFix website are the
          property of myWindowsFix or its partners and may not be used without
          written permission. You may not reproduce or distribute any content,
          trademarks, or logos for commercial purposes without written
          permission from myWindowsFix.
        </p>
        <h4>Privacy Policy</h4>
        <p>
          myWindowsFix respects your privacy and is committed to protecting your
          personal information. myWindowsFix will not share or sell any of your
          personal information to third parties. For more information, please
          review the Privacy Policy on the myWindowsFix website.
        </p>
        <h4>Governing Law and Jurisdiction</h4>
        <p>
          These terms and conditions shall be governed by and construed in
          accordance with the laws of the jurisdiction in which myWindowsFix
          operates. Any disputes arising from these terms and conditions will be
          resolved in the courts of the jurisdiction in which myWindowsFix
          operates.
        </p>
        <h4>Miscellaneous</h4>
        <p>
          These terms and conditions constitute the entire agreement between you
          and myWindowsFix and supersede any prior agreements or understandings,
          written or oral. If any provision of these terms and conditions is
          found to be invalid or unenforceable, the remaining provisions shall
          remain in full force and effect.
        </p>
        <p>
          If you have any questions or concerns regarding these terms and
          conditions, please contact myWindowsFix at support@mywindowsfix.com.
        </p>
      </div>
      <Contact />;
    </div>
  );
};
export default Terms;
